<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <TheSidebar/>
    <TheAside/>

    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheAside from './TheAside'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style>
.c-main {
  padding-top: 1rem !important;
}

.container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.c-main > div > div > div > div.card {
  margin-bottom: 1rem !important;
}

.card-header > h5,
.card-header:not(.content-center) > .c-icon:first-child {
  vertical-align: -webkit-baseline-middle;
}
</style>